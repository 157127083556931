var klook_c_related_url_map = {
  //used for core.js to isolate c platform releted url to adjust accept-Language header
  user_forget_password: "/v3/affsrv/user/account/find_password_by_email",
  user_login: "/v3/affsrv/user/account/login_email_with_captcha",
  captcha_init: "/v3/affsrv/user/account/captcha_init",
};

var urlObj = {
  // dashboard
  account_overview(currency) {
    return `/v3/affsrv/performance/profit?currency=${currency}`;
  },
  account_admin_overview(currency, uid) {
    return `/v3/affsrv/performance/admin/profit?currency=${currency}&uid=${uid}`;
  },
  general_performance: ({ start, end, aid, currency }) =>
    `/v3/affsrv/performance/general?start=${start}&end=${end}&aid=${aid}&currency=${currency}`,
  general_performance_admin: ({ start, end, aid, currency, uid }) =>
    `/v3/affsrv/performance/admin/general?start=${start}&end=${end}&aid=${aid}&currency=${currency}&uid=${uid}`,
  performance_detail: ({ start, end, aid, currency }) =>
    `/v3/affsrv/performance/detail?start=${start}&end=${end}&aid=${aid}&currency=${currency}`,
  performance_detail_admin: ({ start, end, aid, currency, uid }) =>
    `/v3/affsrv/performance/admin/detail?start=${start}&end=${end}&aid=${aid}&currency=${currency}&uid=${uid}`,
  performance_export: `/v3/affsrv/performance/detail/export`,
  performance_export_admin: `/v3/affsrv/performance/admin/detail/export`,

  // kreator dashboard
  getKolPerformance: ({ start, end, promo_code }) =>
    `/v3/affsrv/performance/kol?start_date=${start}&end_date=${end}&promo_code=${promo_code}`,
  getKolPerformanceAdmin: ({ start, end, uid, promo_code }) =>
    `/v3/affsrv/performance/admin/kol?start_date=${start}&end_date=${end}&uid=${uid}&promo_code=${promo_code}`,
  getKolPromoCodeAdmin: (uid) =>
    `/v3/affsrv/performance/kol/promo_code?uid=${uid}`,
  getKolPromoCode: () =>
    `/v3/affsrv/performance/kol/promo_code`,
  // getKolPromoCode: `/v3/affsrv/performance/kol/promo_code`,

  // ad list
  ads_configuration: `/v3/affsrv/ads/configuration`,
  admin_ads_configuration: `/v3/affsrv/admin/ads/configuration`,
  ads_configuration_export: "/v3/affsrv/ads/configuration/export",
  // uid start end pid  ad_type
  admin_ads_configuration_export: "/v3/affsrv/admin/ads/configuration/export",

  get_site_list: "/v3/affsrv/websites",
  get_site_list_by_id: (uid) =>
    `/v3/affsrv/admin/websites?uid=${uid}`,
  get_admin_site_list: "/v3/affsrv/admin/websites",
  user_info: "/v3/affsrv/user",
  user_info_masked: "/v4/affsrv/user",
  update_user_info: "/v3/affsrv/user/manager",
  get_user_detail: "/v3/affsrv/user/detail",
  get_user_detail_masked: "/v4/affsrv/user/detail",
  // admin_website: "/ajax/v2/admin_website",
  contact_us: "/v3/affsrv/user/contact_us",
  transfer_users: "/v3/affsrv/admin/user/transefer",
  get_admin_list: "/v3/affsrv/admin/user/admin_list",
  get_monthly_share: "/v3/affsrv/payment/monthly_share",
  sendInvoice: "/v3/affsrv/user/payment/invoice",
  sendAdminInvoice: "/v3/affsrv/admin/user/payment/invoice",
  get_admin_payments: "/v3/affsrv/admin/payments/list",
  get_admin_payments_masked: "/v4/affsrv/admin/payments/list",
  get_admin_payment_detail_by_uid_fn: (uid) =>
    `/v3/affsrv/admin/payments/detail/${uid}`,
  get_admin_payment_masked_detail_by_uid_fn: (uid) =>
    `/v4/affsrv/admin/payments/detail/${uid}`,
  change_user_payment: (uid) => `/v3/affsrv/admin/user/${uid}/suspension`,
  // get_income_detail : '/ajax/v2/income_detail',
  get_income_detail: "/v3/affsrv/income_detail",
  get_recent_summary: "/v3/affsrv/recent_summary",
  download_finance_template: "/v3/affsrv/admin/finance/report",
  upload_finance_template: "/v3/affsrv/admin/finance/report",
  get_my_bonus_info: "/v3/affsrv/bonus",
  update_bonus_rate: "/v3/affsrv/admin/payment/update_bonus_rate",
  update_channel_type: "/v3/affsrv/admin/website/channel_type",

  // dynamic commission
  // get_category: "/ajax/v2/category",
  get_book_user_country: "/v3/affsrv/get_book_user_country",
  get_admin_website_list: "/v3/affsrv/admin/website_list",
  // get_commission_list: "/ajax/v2/commission_rule/filter",
  check_commission_rule: "/v3/affsrv/check_commission_rule",
  // commission_rule_create: "/ajax/v2/commission_rule/create",
  commission_get_klook_ranges: "/v3/affsrv/get_klook_ranges",
  get_handle_rule_permissions: "/v4/affsrv/admin/affiliate/rule/permission",
  // car renatl destination
  get_car_destination: "/v3/affsrv/car/destination",
  // commission_rule_stop: (rule_id) => `/ajax/v2/commission_rule/stop/${rule_id}`,
  // commission_rule_delete: (rule_id) =>
  //   `/ajax/v2/commission_rule/delete/${rule_id}`,
  // commission_rule_update: (rule_id) =>
  //   `/ajax/v2/commission_rule/update/${rule_id}`,

  //乐活大方相关
  list_lohas_ads: "/ajax/v2/list_lohas_ads",
  lohas_ad_delete: "/ajax/v2/lohas_ad/delete",
  lohas_ad_add: "/ajax/v2/lohas_ad/add",
  lohas_ad_update: "/ajax/v2/lohas_ad/update",

  // billing currency
  payment_currency: "/v3/affsrv/payment/currency", // GET PUT
  payment_currency_admin: "/v3/affsrv/admin/payment/currency", // GET PUT
  payment_currency_uid: (uid) => `/v3/affsrv/admin/payment/currency?uid=${uid}`,
  // 新增 /v3 类型接口, 作以区分
  user_financial: "/v3/affsrv/user/finance",
  user_taxation: "/v3/affsrv/user/taxation",
  user_payment: "/v3/affsrv/user/payment",
  user_payment_masked: "/v4/affsrv/user/payment",
  get_payment_bank_fields: "/v3/affsrv/payment/bank/fields",
  send_financial_otp: "/v3/affsrv/user/otp",
  get_gift_card_list: "/v3/affsrv/activities/giftcard/list",
  get_commission_total: "/v3/affsrv/commission",
  activities_get_data_by_city: (cityId, type) => {
    return `/v3/affsrv/activities/city/${cityId}?ad_type=${type}`;
  },
  activities_get_data_by_city_lang: (cityId, lang) => {
    return `/v3/affsrv/product/hot_activity?city_id=${cityId}&lang=${lang}`;
  },
  activities_validate: "/v3/affsrv/activities/validate",
  hotel_static_widget_validate: "/v3/affsrv/hotels/validate",
  update_solution_type: (uid) => `/v3/affsrv/admin/user/${uid}/update`,
  v3_affsrv_ad: "/v3/affsrv/ads",
  v3_batch_update_aff_type: "/v3/affsrv/admin/user/batch_update_aff_type",
  get_admin_uid: "/v3/affsrv/admin/users",
  get_country_list: "/v3/affsrv/country",
  get_all_country_list: "/v3/affsrv/user/strativearea/countries", // 获取全量地区列表
  update_website_list: "/v3/affsrv/websites",
  search_box_vertical_list: "/v3/affsrv/ads/search/domain",
  get_special_activity_list: "/v3/affsrv/activities/special/list",
  get_category_commission: "/v3/affsrv/activities/category/commission",
  get_ad_performance: "/v3/affsrv/ads/performance",
  gen_ad_performance_report: "/v3/affsrv/ads/performance/report",
  network_publisher_list: "/v3/affsrv/admin/publisher",
  gen_network_publisher_report: "/v3/affsrv/admin/publisher/report",
  websites_list: "/v3/affsrv/websites",
  ad_type_map: "/v3/affsrv/ads/adtype",
  email_validation: "/v3/affsrv/user/email/validation",
  aff_user_register: "/v3/affsrv/user/register",
  change_user_status: (uid) => `/v3/affsrv/admin/user/${uid}/status`,
  change_user_checkstatus: (uid) => `/v3/affsrv/admin/user/${uid}/checkstatus`,
  user_activation: (token) => {
    return "/v3/affsrv/user/activation/" + token;
  },
  resend_activation: "/v3/affsrv/user/email/resend",

  // Widget渲染相关
  widgetRenderData: (adid) => {
    return `/v3/affsrv/ads/widget?adid=${adid}`;
  },
  search_box_render_data: (adid) => {
    return `/v3/affsrv/ads/search?adid=${adid}`;
  },

  static_banner_render_data: (adid) => {
    return `/v3/affsrv/ads/banner/static?adid=${adid}`;
  },

  activity_banner_render_data: (adid) => {
    return `/v3/affsrv/ads/banner/activity?adid=${adid}`;
  },

  // auto_dynamic_widget
  auto_widget: "/v3/affsrv/ads/autowidget",
  // user role
  user_role_list: "/v3/affsrv/admin/user/group",
  internal_user_list: "/v3/affsrv/admin/user",
  internal_user_update: "/v3/affsrv/admin/user/group",

  // co-brnad 相关
  co_brand_upload_img: "/v3/affsrv/websites/cobrands/upload",
  co_brand_aid_list: "/v3/affsrv/websites",
  co_brand_websites_cobrands: "/v3/affsrv/websites/cobrands",
  co_brand_history_list: "/v3/affsrv/websites/cobrands/operation",
  co_brand_admin_history_list: "/v3/affsrv/admin/websites/cobrands/operation",

  //admin -> affiliates admin 页面
  admin_get_affiliates_list: "/v3/affsrv/admin/affiliates_list",
  admin_post_affiliate_info: "/v3/affsrv/admin/affiliate",
  admin_export_affiliates_list: "/v3/affsrv/admin/affiliates/export",

  // admin -> kreator 操作
  admin_get_kreator_detail: (uid) => `/v3/affsrv/admin/user/kreator?uid=${uid}`,
  admin_handle_kreator_status: "/v3/affsrv/admin/user/kreator",
  admin_get_kreator_list: "/v3/affsrv/admin/user/kreators",
  admin_export_kreators: "/v3/affsrv/admin/user/kreators/export",
  admin_batch_reject_kreator: "/v3/affsrv/admin/user/kreators/batch_reject",

  // coupon 操作相关接口
  get_coupon_templates: "/v3/affsrv/admin/user/kreator/coupon_template",
  get_promo_code_by_id: (id) =>
    `/v3/affsrv/admin/user/kreator/coupon?user_id=${id}`,
  get_reject_reasons: "/v3/affsrv/admin/user/kreator/refuse_reason",
  create_promo_code: "/v3/affsrv/admin/user/kreator/coupon",
  logic_delete: '/v3/affsrv/admin/user/kreator/logic_delete',

  //admin -> monthly report 页面，order/ticket 报表 (by month)
  admin_monthly_order_export: "/v3/affsrv/admin/monthly/orders/export",
  admin_monthly_ticket_export: "/v3/affsrv/admin/monthly/tickets/export",
  admin_monthly_order: "/v3/affsrv/admin/monthly/orders",
  admin_monthly_ticket: "/v3/affsrv/admin/monthly/tickets",
  admin_monthly_status_billing_report:
    "/v3/affsrv/admin/billing/tickets/export",
  admin_monthly_status_booking_report:
    "/v3/affsrv/admin/booking/tickets/export",

  //用户侧，performance页面，order/ticket 报表 (by date range)
  user_order: "/v3/affsrv/user/orders",
  user_order_export: "/v3/affsrv/user/orders/export",
  user_ticket: "/v3/affsrv/user/tickets",
  user_ticket_export: "/v3/affsrv/user/tickets/export",
  user_billing_ticket_report: "/v3/affsrv/user/billing/tickets/export",
  user_booking_ticket_report: "/v3/affsrv/user/booking/tickets/export",

  //admin侧，admin panel页面，order/ticket 报表 (by date range)
  admin_order: "/v3/affsrv/admin/orders",
  admin_order_export: "/v3/affsrv/admin/orders/export",
  admin_ticket: "/v3/affsrv/admin/tickets",
  admin_ticket_export: "/v3/affsrv/admin/tickets/export",

  // expense
  admin_expense: "/v3/affsrv/admin/user/expense",
  admin_expense_edit: (id) => `/v3/affsrv/admin/user/expense/${id}`,
  expense_type: "/v3/affsrv/admin/user/expense/type",
  delete_expense: (id) => `/v3/affsrv/admin/user/expense/flag/${id}`,
  search_user: (query) => `/v3/affsrv/admin/payment/user?query=${query}`,
  expense_export: (uid, month) =>
    `/v3/affsrv/admin/user/expense/export?uid=${uid}&month=${month}`,

  // dynamic commission 2.0
  get_publish_by_wid: "/v3/affsrv/publishers/query",
  search_commission_campaign: "/v3/affsrv/admin/commission/campaign/query",
  create_campaign: "/v3/affsrv/admin/commission/campaign/create",
  update_campaign: (campaign_id) =>
    `/v3/affsrv/admin/commission/campaign/${campaign_id}/update`,
  delete_campaign: (campaign_id) =>
    `/v3/affsrv/admin/commission/campaign/${campaign_id}/delete`,
  terminate_campaign: (campaign_id) =>
    `/v3/affsrv/admin/commission/campaign/${campaign_id}/terminate`,
  get_website_list_by_wid: `/v3/affsrv/websites/query`,
  template_id_mapping: `/v3/affsrv/admin/commission/template_id_mapping`,
  campaign_rule_create: (campaign_id) =>
    `/v3/affsrv/admin/commission/campaign/${campaign_id}/rule/create`,
  campaign_rule_update: (campaign_id, campaign_rule_id) =>
    `/v3/affsrv/admin/commission/campaign/${campaign_id}/rule/${campaign_rule_id}/update`,
  campaign_rule_delete: (campaign_id, campaign_rule_id) =>
    `/v3/affsrv/admin/commission/campaign/${campaign_id}/rule/${campaign_rule_id}/delete`,
  campaign_rule_query: `/v3/affsrv/admin/commission/campaign/rule/query`,
  campaign_search_by_id: (campaign_id) =>
    `/v3/affsrv/admin/commission/campaign/${campaign_id}/retrieve`,
  campaign_rule_search_by_id: (campaign_id, campaign_rule_id) =>
    `/v3/affsrv/admin/commission/campaign/${campaign_id}/rule/${campaign_rule_id}/retrieve`,

  // notification
  notification_create: "/v3/affsrv/admin/notification",
  notification_history: "/v3/affsrv/admin/notification/action",
  notification_upload_img: "/v3/affsrv/admin/notification/image",
  notification_detail: (id) => `/v3/affsrv/admin/notification/${id}/detail`,
  notification_read_totals: `/v3/affsrv/notification/read_status`,
  notification_list: "/v3/affsrv/notification",
  getAdminList: "/v3/affsrv/admin/user?gid=1,2&pcount=100",
  notification_publisher_detail: (id) => `/v3/affsrv/notification/${id}/detail`,
  notification_publisher_read: (id) =>
    `/v3/affsrv/notification/${id}/read_status`,

  getAllNotificationId: "/v3/affsrv/notification/all/ids", //获取当前用户所有noti id 和 未读的 noti id
  getNotificationDetailById: (id) =>
    "/v3/affsrv/notification/details?noti_ids=" + id, // 获取noti_id 的详情
  setAllRead: "/v3/affsrv/notification/all/read_status", // 标记所有notification为已读

  // 登录注册相关
  session: "/session",
  logout: "/logout",
  user_forget_password: klook_c_related_url_map.user_forget_password,
  user_login: klook_c_related_url_map.user_login,
  captcha_init: klook_c_related_url_map.captcha_init,
  get_affiliate_coupon_codes: "/v3/affsrv/ads/coupons",
  export_affiliate_coupon_codes: "/v3/affsrv/ads/coupons/export",
  csrf_token: "/v3/affsrv/csrf-token",
  //
  create_user: "/v4/affsrv/user", // 创建affiliate用户(关联补全信息并绑定)
  create_toc_user: "/v4/affsrv/user/register",
  new_login: "/v3/affsrv/session", // GET /v3/affsrv/session?token=<用户中心授权token>

  // ads_render
  // hotel
  ads_render_hotel_dynamic_widget: "/v3/affsrv/ads/hotelwidget/dynamic",
  ads_render_hotel_static_widget: "/v3/affsrv/ads/hotelwidget/static",
  ads_render_hotel_auto_dynamic_widget: "/v3/affsrv/ads/hotelwidget/auto",

  // kepler相关
  kepler_experiments: "/v2/usrcsrv/hit/experiments",

  // product_list 页面
  product_list_config: "/v3/affsrv/ads/recommend-product-page-config", // 获取所有目的地和类别
  product_list_data: "/v3/affsrv/ads/recommend-product", // 获取 product_list 数据
  product_list_gen_links: "/v3/affsrv/ads/gen-listing-link", // 生成链接

  // 广告上报
  ads_event: "/v3/affsrv/ads/event",

  // 下载
  be2aff_mapping: "/v3/affsrv/admin/commission/be2aff_mapping",
  special_allow_list_export: "/v3/affsrv/admin/activity/export",
  special_allow_list_upload: "/v3/affsrv/admin/activity/batch",
  special_allow_list_history: "/v3/affsrv/admin/activity/history",
  special_allow_list_confirm: "/v3/affsrv/admin/activity/status",
  special_allow_list_activity: `/v3/affsrv/activity/category`,
  activity_category_export: (category_id) =>
    `/v3/affsrv/activity/category/export?aff_category_id=${category_id}`,

  help_login_menu_list: "/v3/affsrv/feedback/faqcontent/login",
  help_unlogin_menu_list: "/v3/affsrv/feedback/faqcontent/no-login",
  help_faq_feedback: "/v3/affsrv/feedback/faq",

  // feedback
  feedback_get_question: "/v3/affsrv/feedback/question",
  feedback_post_cast: "/v3/affsrv/feedback/csat",

  // product feed
  product_feed_report: "/v3/affsrv/product/feed",

  // 用于hotel_link mannul input搜索框
  hotelManualSearch: (keyword, lang) =>
    `/v3/affsrv/product/all/suggest?keyword=${keyword}&lang=${lang}`,

  // 用于hotel_link和hotel_widget property搜索框
  hotelCommonSearch: (keyword, lang) =>
    `/v3/affsrv/product/hotel/suggest?keyword=${keyword}&lang=${lang}`,

  // hotel deals 技术文档 https://klook.larksuite.com/docx/doxusid4quusfZVwtGxvEEepa7g
  deals_destination: (tag_id) =>
    `/v3/affsrv/product/hotel/deal/destination?tag_id=${tag_id}`,
  get_deals_list: (aid, country_id, city_id, tag_id, currency, lang) =>
    `/v3/affsrv/product/hotel/deal?aid=${aid}&country_id=${country_id}&city_id=${city_id}&tag_id=${tag_id}&lang=${lang}&currency=${currency}`,
  admin_get_deals_list: (aid, country_id, city_id, tag_id, lang, currency) =>
    `/v3/affsrv/admin/product/hotel/deal?aid=${aid}&country_id=${country_id}&city_id=${city_id}&tag_id=${tag_id}&lang=${lang}&currency=${currency}`,
  export_deals_list: (aid, country_id, city_id, tag_id, currency, lang) =>
    `/v3/affsrv/product/hotel/deal/export?aid=${aid}&country_id=${country_id}&city_id=${city_id}&tag_id=${tag_id}&currency=${currency}&lang=${lang}`,
  admin_export_deals_list: (aid, country_id, city_id, tag_id, currency, lang) =>
    `/v3/affsrv/admin/product/hotel/deal/export?aid=${aid}&country_id=${country_id}&city_id=${city_id}&tag_id=${tag_id}&currency=${currency}&lang=${lang}`,

  get_deals_category: "/v3/affsrv/product/hotel/deal/category",
  deals_subscription_status: "/v3/affsrv/product/subscription?product_type=1", //  普通用户显示订阅状态
  deals_subscription: "/v3/affsrv/product/subscription", //  普通用户订阅/取消订阅
  admin_deals_subscription: "/v3/affsrv/admin/product/subscription", //  admin用户订阅/取消订阅
  deals_subscription_freq: "/v3/affsrv/product/subscription/freq", //  - 普通用户修改订阅频率
  admin_subscription_freq: "/v3/affsrv/admin/product/subscription/freq", //  - admin用户修改订阅频率
  admin_subscription_status: (uid) =>
    `/v3/affsrv/admin/product/subscription?uid=${uid}`, // - admin显示订阅状态
  admin_commission_change_rule_type: "/v4/affsrv/admin/affiliate/rule/status/edit",
  admin_commission_bulk_edit_rule_time: "/v4/affsrv/admin/affiliate/rule/time/bulk/edit",
  admin_commission_handle_rule_tag: "/v4/affsrv/admin/affiliate/rule/tag/create",
  admin_commission_handle_edit_tag: "/v4/affsrv/admin/affiliate/rule/tag/edit",
  admin_commission_get_simulation_rule: "/v4/affsrv/admin/affiliate/rule/fact/hit",
  admin_commission_get_rule_list: "/v4/affsrv/admin/affiliate/rule/query",
  admin_commission_get_rule_tag_list: "/v4/affsrv/admin/affiliate/rule/tag/query",
  admin_commission_create_rule: "/v4/affsrv/admin/affiliate/rule/create",
  admin_commission_edit_rule: "/v4/affsrv/admin/affiliate/rule/edit",
  admin_commission_query_rule_version: "/v4/affsrv/admin/affiliate/rule/version/query",
  admin_commission_clone_rule: "/v4/affsrv/admin/affiliate/rule/clone",
  admin_commission_get_category: "/v4/affsrv/admin/affiliate/rule/category/query",
  admin_get_countries: "/v4/affsrv/strativearea/countries",
  admin_get_cities: (id, query, city_ids) => `/v4/affsrv/strativearea/cities?country_id=${id}&query=${query}&city_id=${city_ids}`,
  admin_commission_get_simulation_order_rule: "/v4/affsrv/admin/affiliate/rule/order/hit",
  admin_commission_edit_end_time: "/v4/affsrv/admin/affiliate/rule/end_time/edit",
};

urlObj.klook_c_related_url_map = klook_c_related_url_map;

export default urlObj;
