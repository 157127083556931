import { $t, isEnglish } from "./utils";
import klook from "klook";

export const DEMO_WEBSITE_URL = [
  "https://www.facebook.com",
  "https://www.tiktok.com",
  "https://www.instagram.com",
  "https://www.youtube.com",
  "https://www.twitter.com",
];
/* function emitError(error) {
  this.$emit("validate_error");
} */

function isEmpty(val) {
  if (
    typeof val == "undefined" ||
    String(val).trim().length == 0 ||
    val === null
  ) {
    return true;
  }
  return false;
}

/* function isInteger(value) {
  return /^\d+$/.test(value);
} */

/* function lettersOnly(value) {
  return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/i.test(
    value
  );
} */

function isTopActivityMode(activityType) {
  return activityType == 1;
}

function isValidateWebsiteUrl(value) {
  return /^(http|https):\/\/.*$/.test(value);
}

function isValidateOuterUrl(value) {
  const demoStr = DEMO_WEBSITE_URL.join("|");
  const outerReg = new RegExp(`(${demoStr})\/?$`);
  return outerReg.test(value);
}

/* function validateLettersOnly(rule, value, callback) {
  if (lettersOnly(value)) {
    callback();
  } else {
    callback(new Error($t("FORM_RULES_global_validate_lettersonly")));
  }
} */

function validateNotEmpty(rule, value, callback) {
  if (isEmpty(value)) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else {
    callback();
  }
}

function validateEmail(rule, value, callback) {
  if (!klook.checkEmail(value)) {
    callback(new Error($t("FORM_RULES_error_email_format_invalid")));
  } else {
    callback();
  }
}

function validateSignUpPassword(rule, value, callback) {
  if (
    value.length < 8 ||
    value.length > 20 ||
    !/\d+/.test(value) ||
    !/[a-zA-Z]/.test(value)
  ) {
    callback(new Error($t("FORM_RULES_error_validate_password")));
  } else {
    callback();
  }
}

function validatePassword(rule, value, callback) {
  if (isEmpty(value)) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else {
    callback();
  }
}

function validateSelectedCategory(rule, value, callback) {
  if (
    isTopActivityMode(this.form_data.activityMode) &&
    isEmpty(this.form_data.categoryId)
  ) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else {
    callback();
  }
}

function validateSelectedCity(rule, value, callback) {
  if (
    isTopActivityMode(this.form_data.activityMode) &&
    isEmpty(this.form_data.selectedCityId)
  ) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else {
    callback();
  }
}

function validateActivityAmount(rule, value, callback) {
  if (
    isTopActivityMode(this.form_data.activityMode) &&
    isEmpty(this.form_data.activityAmount)
  ) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else {
    callback();
  }
}

function validateAffiliateAgreement(rule, value, callback) {
  if (!value) {
    callback(new Error($t("FORM_RULES_affiliate_agreement_error")));
  } else {
    callback();
  }
}

function validatePeopleName(rule, value, callback) {
  if (isEmpty(value)) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else if (isAllSpecialChar(value)) {
    callback(new Error($t("116744")));
  } else {
    callback();
  }
}

function validateCampaignName(rule, value, callback) {
  if (isEmpty(value)) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else if (value.length > 64) {
    callback(new Error("Length less than 64"));
  } else {
    callback();
  }
}

function validateBankNumberInput(rule, value, callback) {
  const REG = new RegExp(/^[0-9]+$/);

  if (isEmpty(value)) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else if (!REG.test(value)) {
    callback(new Error($t("108052")));
  } else {
    callback();
  }
}
function validateBankTaxInput(rule, value, callback) {
  if (isEmpty(value)) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else {
    callback();
  }
}

function validateAccountName(rule, value, callback) {
  // 允许字母、数字、空格、常见符号、中文（简体和繁体）、日文字符
  const reg1 = new RegExp(/^(?!.*[，……·！｀：¥（）～——？“”‘’；｜`~@^_\|=\\])[a-zA-Z0-9\s?.(),'+:\-\/\u4e00-\u9fff\u3400-\u4DBF\u20000-\u2A6DF\u2A700-\u2B73F\u2B740-\u2B81F\u2B820-\u2CEAF\u2CEB0-\u2EBEF\u2F800-\u2FA1F\uF900-\uFAFF\u3040-\u309F\u30A0-\u30FF\u31F0-\u31FF\uFF00-\uFFEF\uD840\uDC00-\uD87F\uDFFF]*$/u);
  // 不允许以特定符号开始或结束
  const reg2 = new RegExp(/^(?![-:/])(?!.*[-:/]$).*$/);
  // 不允许出现连续的两个斜杠
  const reg3 = new RegExp(/^(?!.*\/\/).*$/);

  if (!reg1.test(value)) {
    callback(new Error($t("173832")));
  } else if (!reg2.test(value)) {
    callback(new Error($t("173833")));
  } else if (!reg3.test(value)) {
    callback(new Error($t("173834")));
  } else {
    callback();
  }
}

// 仅允许字母和数字
function validateNumberAndLetter(rule, value, callback) {
  if (!/^[A-Za-z0-9]*$/.test(value)) {
    callback(new Error($t("173835")));
  } else {
    callback();
  }
}

// 仅允许大写字母和数字
function validateNumberAndCapital(rule, value, callback) {
  if (!/^[A-Z0-9]*$/.test(value)) {
    callback(new Error($t("173835")));
  } else {
    callback();
  }
}

// 仅输入8或11位
function validateDigit(rule, value, callback) {
  if (!/^(?:[A-Z0-9]{8}|[A-Z0-9]{11})$/.test(value)) {
    callback(new Error($t("173836")));
  } else {
    callback();
  }
}

function isSpecialChar(value) {
  let s = String(value).trim();
  let reg = new RegExp(/[\{\}\<\>\[\]&"'*$#@&%:]/);
  return reg.test(s);
}

function isAllSpecialChar(value) {
  let s = String(value);
  const reg1 = new RegExp(
    /[`~!@#\$%\^&*()\/_+=|;:{}\\＞＜\' ,[\]\.<>\?！\'￥%…（）—【】‘；：”“’。，、？\-\s·丶＇〖〗＃＊～『』〔〕「」‖〈〉«»‹›"¿¥£€﹉–´＂¢฿♀♂｛｝《》]/
  );
  const reg2 = new RegExp(
    /\u00A9|\u00AE|[\u2000-\u3300]|\uD83C[\uD000-\uDFFF]|\uD83D[\uD000-\uDFFF]|\uD83E[\uD000-\uDFFF]/
  );
  return reg1.test(s) || reg2.test(s);
}

function validateReferenceName(rule, value, callback) {
  const reg1 = new RegExp(/[{}]/);
  if (isEmpty(value)) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else if (reg1.test(value)) {
    callback(new Error('Batch name is not allowed to contain symbols "{}".'));
  } else {
    callback();
  }
}

function validateWebsiteName(rule, value, callback) {
  if (isSpecialChar(value)) {
    callback(new Error($t("FORM_RULES_no_allow_special_chart")));
  } else {
    callback();
  }
}

function website_name_english(rule, value, callback) {
  if (isEmpty(value)) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else if (!isEnglish(value)) {
    callback(new Error($t("FORM_RULES_edit_website_name_english_rules")));
  } else {
    callback();
  }
}

export function validateKolPreferCode(rule, value, callback) {
  const reg = new RegExp(/^[A-Za-z0-9]+$/);
  if (!reg.test(value) || value.length <= 6) {
    callback(new Error($t("102771")));
  } else {
    callback();
  }
}

export function validateKolWebsiteUrl(rule, value, callback) {
  if (!isValidateWebsiteUrl(value)) {
    callback(new Error($t("FORM_RULES_not_a_valid_website_url")));
  } else if (isValidateOuterUrl(value)) {
    callback(new Error($t("100936")));
  } else {
    callback();
  }
}
function validateWebsiteUrl(rule, value, callback) {
  if (!isValidateWebsiteUrl(value)) {
    callback(new Error($t("FORM_RULES_not_a_valid_website_url")));
  } else {
    callback();
  }
}

function validateChannelName(rule, value, callback) {
  if (isEmpty(value)) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else {
    callback();
  }
}

function validateAdChannel(rule, value, callback) {
  if (isEmpty(value)) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else {
    callback();
  }
}

function validateAdPage(rule, value, callback) {
  if (isEmpty(value)) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else {
    callback();
  }
}

function validateAlipayAccount(rule, value, callback) {
  if (isEmpty(value)) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else {
    callback();
  }
}

function validateConfirmAlipayAccount(rule, value, callback) {
  if (this.form_data.alipay_id != this.form_data.alipay_id_confirm) {
    callback(new Error($t("FORM_RULES_account_does_not_match")));
  } else {
    callback();
  }
}

function validateConfirmPaypalAccount(rule, value, callback) {
  if (this.form_data.paypal_id != this.form_data.paypal_id_confirm) {
    callback(new Error($t("FORM_RULES_account_does_not_match")));
  } else {
    callback();
  }
}

function validateActivityAdActivityList(rule, value, callback) {
  //cache 3 activities when change to 1
  for (var index = 0; index < this.activity_length; index++) {
    if (
      this.form_data.selected_activities[index] == "undefined" ||
      this.form_data.selected_activities[index] == "" ||
      typeof this.form_data.selected_activities[index] == "undefined"
    ) {
      // return callback(new Error(`please select ${this.activity_length} activity`))
      return callback(
        new Error($t("banner_activity_warning", [this.activity_length]))
      );
    }
  }
  callback();
}

// validateTextLinkActivityId
function validateTextLinkActivityId(rule, value, callback) {
  if (this.form_data.urlSearchMethod == 2) {
    if (!this.form_data.selectedActivityId) {
      return callback(new Error($t("FORM_RULES_cannot_be_empty")));
    }
  }
  callback();
}

function validateSourceInfo(rule, value, callback) {
  if (isEmpty(value)) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else if (isSpecialChar(value)) {
    callback(new Error($t("FORM_RULES_no_allow_special_chart")));
  } else {
    callback();
  }
}

function validateRemark(rule, value, callback) {
  if (value && value.length >= 256) {
    callback(new Error($t("FORM_RULES_expense_form_rule_remark")));
  } else {
    callback();
  }
}

function validateAmount(rule, value, callback) {
  if (isEmpty(value)) {
    callback(new Error($t("FORM_RULES_cannot_be_empty")));
  } else if (+value > 10000000000 || +value < -10000000000) {
    callback(new Error($t("FORM_RULES_expense_form_rule_amount")));
  } else {
    callback();
  }
}

let validateManagerEmail = (rule, value, callback) => {
  if (value && !/\S+@klook.com$/.test(value)) {
    callback(new Error("@klook.com"));
  } else {
    callback();
  }
};

var formRules = function (custom_validation_obj) {
  var const_validation = {
    manager_email: [
      {
        validator: validateManagerEmail.bind(this),
        trigger: "blur",
      },
    ],
    affiliate_agreement: [
      {
        required: "true",
        validator: validateAffiliateAgreement.bind(this),
        trigger: "change",
      },
    ],
    firstname: [
      {
        required: "true",
        validator: validatePeopleName.bind(this),
        trigger: "blur",
      },
    ],
    signup_firstname: [
      {
        required: "true",
        validator: validatePeopleName.bind(this),
        trigger: "blur",
      },
    ],
    lastname: [
      {
        required: "true",
        validator: validatePeopleName.bind(this),
        trigger: "blur",
      },
    ],
    signup_lastname: [
      {
        required: "true",
        validator: validatePeopleName.bind(this),
        trigger: "blur",
      },
    ],
    login_email: [
      {
        required: "true",
        validator: validateEmail.bind(this),
        trigger: "blur",
      },
    ],
    email: [
      {
        required: "true",
        validator: validateEmail.bind(this),
        trigger: "blur",
      },
    ],
    password: [
      {
        required: "true",
        validator: validatePassword.bind(this),
        trigger: "blur",
      },
    ],
    sign_up_password: [
      {
        required: "true",
        validator: validateSignUpPassword.bind(this),
        trigger: "blur",
      },
    ],
    website_name: [
      {
        required: "true",
        validator: website_name_english.bind(this),
        trigger: "blur",
      },
    ],
    name_local: [
      {
        required: "true",
        validator: validateWebsiteName.bind(this),
        trigger: "blur",
      },
    ],
    website_name_local: [
      {
        required: "true",
        validator: validateWebsiteName.bind(this),
        trigger: "blur",
      },
    ],
    name: [
      //website_name
      {
        required: "true",
        validator: website_name_english.bind(this),
        trigger: "blur",
      },
    ],
    websiteURL: [
      //website_url
      {
        required: "true",
        validator: validateWebsiteUrl.bind(this),
        trigger: "blur",
      },
    ],
    websiteUrl: [
      {
        required: "true",
        validator: validateWebsiteUrl.bind(this),
        trigger: "blur",
      },
    ],
    website: [
      //website
      {
        required: "true",
        validator: validateWebsiteUrl.bind(this),
        trigger: "blur",
      },
    ],
    url: [
      //website_url
      {
        required: "true",
        validator: validateWebsiteUrl.bind(this),
        trigger: "blur",
      },
    ],
    website_url: [
      //website_url
      {
        required: "true",
        validator: validateWebsiteUrl.bind(this),
        trigger: "blur",
      },
    ],
    channelName: [
      {
        required: "true",
        validator: validateChannelName.bind(this),
        trigger: "blur",
      },
    ],
    adChannel: [
      {
        required: "false",
        validator: validateAdChannel.bind(this),
        trigger: "change",
      },
    ],
    adPage: [
      {
        required: "false",
        validator: validateAdPage.bind(this),
        trigger: "change",
      },
    ],
    linkTitle: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    activity_ad_activity_list: [
      {
        required: "true",
        validator: validateActivityAdActivityList.bind(this),
        // trigger: 'change'
      },
    ],
    //alipay
    alipay_id: [
      {
        required: "true",
        validator: validateAlipayAccount.bind(this),
        trigger: "blur",
      },
    ],
    alipay_id_confirm: [
      {
        required: "true",
        validator: validateConfirmAlipayAccount.bind(this),
        trigger: "blur",
      },
    ],
    //paypal
    paypal_id: [
      {
        required: "true",
        validator: validateEmail.bind(this),
        trigger: "blur",
      },
    ],
    paypal_id_confirm: [
      {
        required: "true",
        validator: validateConfirmPaypalAccount.bind(this),
        trigger: "blur",
      },
    ],
    //bank info

    bank_name_branch_name: [
      {
        required: "true",
        validator: validateBankTaxInput.bind(this),
        trigger: "blur",
      },
    ],
    branch_code: [
      {
        required: "true",
        validator: validateBankNumberInput.bind(this),
        trigger: "blur",
      },
    ],
    iban_number: [
      {
        required: "true",
        validator: validateBankTaxInput.bind(this),
        trigger: "blur",
      },
    ],
    sort_code: [
      {
        required: "true",
        validator: validateBankNumberInput.bind(this),
        trigger: "blur",
      },
    ],
    bank_name: [
      {
        required: "true",
        validator: validateBankTaxInput.bind(this),
        trigger: "blur",
      },
    ],
    bank_account_name: [
      {
        required: "true",
        validator: validateBankTaxInput.bind(this),
        trigger: "blur",
      },
      {
        required: "true",
        validator: validateAccountName.bind(this),
        trigger: "blur",
      },
    ],
    bank_account_id: [
      {
        required: "true",
        validator: validateBankTaxInput.bind(this),
        trigger: "blur",
      },
      {
        required: "true",
        validator: validateNumberAndLetter.bind(this),
        trigger: "blur",
      },
    ],
    swift_code: [
      {
        required: "true",
        validator: validateBankTaxInput.bind(this),
        trigger: "blur",
      },
      {
        required: "true",
        validator: validateNumberAndCapital.bind(this),
        trigger: "blur",
      },
      {
        required: "true",
        validator: validateDigit.bind(this),
        trigger: "blur",
      },
    ],
    bank_code: [
      {
        required: "true",
        validator: validateBankNumberInput.bind(this),
        trigger: "blur",
      },
    ],
    ifsc_code: [
      {
        required: "true",
        validator: validateBankTaxInput.bind(this),
        trigger: "blur",
      },
    ],
    routing_code: [
      {
        required: "true",
        validator: validateBankTaxInput.bind(this),
        trigger: "blur",
      },
    ],
    bsb_code: [
      {
        required: "true",
        validator: validateBankNumberInput.bind(this),
        trigger: "blur",
      },
    ],
    websiteId: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    /* region: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "change",
      },
    ], */
    phone: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    verify_code: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    business_name: [
      //used in contact_us
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    question_type: [
      //used in contact_us
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    message: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    // admin_email: [
    //   {
    //     validator: validateNotEmpty.bind(this),
    //     trigger: "blur",
    //   },
    // ],

    // lohas

    lohas_destination: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    img_src: [
      {
        required: "true",
        validator: validateWebsiteUrl.bind(this),
        trigger: "blur",
      },
    ],

    lohas_url: [
      {
        required: "true",
        validator: validateWebsiteUrl.bind(this),
        trigger: "blur",
      },
    ],

    keywords: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    //lohas end

    prefer_lang: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    selectedCityId: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "change",
      },
    ],
    prefer_currency: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    phone_prefix: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    phone_number: [
      {
        required: "true",
        validator: validateBankTaxInput.bind(this),
        trigger: "blur",
      },
    ],
    country_code: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    pid: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    pname: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    country: [
      //used in contact us
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    category: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    select_category: [
      {
        required: "true",
        validator: validateSelectedCategory.bind(this),
      },
    ],
    select_city: [
      {
        required: "true",
        validator: validateSelectedCity.bind(this),
      },
    ],
    activity_amount: [
      {
        required: "true",
        validator: validateActivityAmount.bind(this),
      },
    ],
    targetAudienceRegion: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "change",
      },
    ],
    monthlyVisitors: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "change",
      },
    ],
    region: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    selectedActivityId: [
      {
        required: "true",
        validator: validateTextLinkActivityId.bind(this),
        trigger: "change",
      },
    ],
    source_type: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    source_info: [
      {
        required: "true",
        validator: validateSourceInfo.bind(this),
        trigger: "blur",
      },
    ],
    nationality: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    id_passport_type: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    id_passport_number: [
      {
        required: "true",
        validator: validateBankTaxInput.bind(this),
        trigger: "blur",
      },
    ],
    id_passport_name: [
      {
        required: "true",
        validator: validateBankTaxInput.bind(this),
        trigger: "blur",
      },
    ],
    gender: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    date_of_birth: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    residential_address: [
      {
        required: "true",
        validator: validateBankTaxInput.bind(this),
        trigger: "blur",
      },
    ],
    bank_address: [
      {
        required: "true",
        validator: validateBankTaxInput.bind(this),
        trigger: "blur",
      },
    ],
    business_license_id: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    uid: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    incur_date: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    type: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    amount: [
      {
        required: "true",
        validator: validateAmount.bind(this),
        trigger: "blur",
      },
    ],
    remark: [
      {
        required: "true",
        validator: validateRemark.bind(this),
        trigger: "blur",
      },
    ],
    campaign_type: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    wid_list: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    campaign_name: [
      {
        required: "true",
        validator: validateCampaignName.bind(this),
        trigger: "blur",
      },
    ],
    ranges: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    title: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    html: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "blur",
      },
    ],
    hotelId: [
      {
        required: "true",
        validator: validateNotEmpty.bind(this),
        trigger: "change",
      },
    ],
    promo_code: {
      required: "true",
      trigger: "blur",
      validator: validateKolPreferCode.bind(this),
    },
    decline_reason: {
      required: "true",
      trigger: "blur",
      validator: validateNotEmpty.bind(this),
    },
    followers_level: {
      required: "true",
      trigger: "blur",
      validator: validateNotEmpty.bind(this),
    },
    "kreator.location": {
      required: "true",
      trigger: "blur",
      validator: validateNotEmpty.bind(this),
    },
    "kreator.followers_level": {
      required: "true",
      trigger: "blur",
      validator: validateNotEmpty.bind(this),
    },
    "kreator.prefer_promo_code": {
      required: "true",
      trigger: "blur",
      validator: validateKolPreferCode.bind(this),
    },
    promo_code: {
      required: "true",
      trigger: "blur",
      validator: validateKolPreferCode.bind(this),
    },
    reference_name: {
      required: "true",
      trigger: "blur",
      validator: validateReferenceName.bind(this),
    },
    batch_id: {
      required: "true",
      trigger: "blur",
      validator: validateNotEmpty.bind(this),
    },
  };

  return _.extend({}, const_validation, custom_validation_obj || {});
};

export default formRules;
